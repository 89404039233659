import { Component, createRef } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import { memberslist } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';
export class Member extends Component {

    config;
    initialSearchState;

    constructor(props) {
        super(props);

        this.dataTable = createRef();

        /* initial search state */
        this.initialSearchState = {
            buyeremail: { field: 'buyeremail', value: '', op: 'LIKE', join: 'AND' },
            status: { field: "status", value: "Active", op: "=", join: "AND" }
        }

        this.state = {
            search: this.initialSearchState,
            searchData: false,
            tableData: [],
            issearch: false,

        }

        this.config = {
            name: 'memberlist',
            url: memberslist,
            params: {},
            columns: [
                { name: 'Buyer Email ', property: 'buyeremail', width: '250px' },
                { name: 'Plan Name', property: 'planname', width: '130px' },
                { name: 'Expiration Date', property: 'enddate', sort: true, width: '130px', columnType: 'custom', customColumn: this.customEndDate },
                { name: 'Status', property: 'status', width: '130px' },
                { name: 'Auto Renewal', property: 'autorenewal', sort: true, width: '130px', columnType: 'custom', customColumn: this.customeAutoRenew },
                {
                    name: '', property: 'id', columnType: 'actions', actions: [], customHeader: "CSV", width: '140px',
                }
            ],
            sort: [{"field": "enddate", "direction": "desc" }],
            field: ['buyeremail', 'planname', 'startdate', 'status', 'enddate', 'autorenewal', 'buyeremail', 'membershipplanid', 'buyerid'],
            defaultFilter: [
                {
                    "field": "status",
                    "value": "Active",
                    "op": "=",
                    "join": "AND"
                }
            ],
            csvColumn: [
                { name: 'Enrolled Date', property: 'startdate',columnType: 'custom', customColumn: this.customStartDate }
            ],
            filename: "membership-report",
            adminUrl: {},
            pagination: true,
            ignoreRowBackground: true
          
        };

        if (props.location.state && props.location.state.datatable && props.location.state.datatable.search) {
            let tempSearch = { ...this.initialSearchState };
            const search = [...props.location.state.datatable.search];

            search.forEach(element => {
                let temp = { ...element };
                if (typeof temp['value'] === 'string') {
                    temp['value'] = temp['value'].replace(/(^\*)|(\*$)/g, '');
                }
                tempSearch[temp['field']] = temp;
            });

            this.state['search'] = tempSearch;
            this.state['issearch'] = Boolean(search.length);
            this.state['searchData'] = true;
            if (this.state['search']['state'].value)
                this.getCity();
            this.enableSearch();
        }
    }


    _handleKeyDown = (e) => {
        
        if ( e.target.value &&  e.key === 'Enter') {
            this.enableSearch()
        }
    }

    componentDidMount() {  
        this.setState({
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }
    customEndDate = (value) => {
        if (value['enddate']) {
            return `${moment.utc(value['enddate']).format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };
    customStartDate = (value) => {
        if (value['startdate']) {
            return `${moment.utc(value['startdate']).format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';

    }
    customeAutoRenew = (value) => {
        if(value['autorenewal'] == 1 ){
            return 'Enabled'

        }
        else if(value['autorenewal'] == 0) {
            return 'Disabled'
        }
        else{
            return '- - -'
        }
    }


    searchInputChange = (event) => {
        let eventValue = event.target.value;
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [event.target.name]: {
                    ...this.state.search[event.target.name],
                    value: eventValue
                }
            }
        }, () => {
         
        });
    }
    resetState = () => {
        this.setState({
            ...this.state,
            search: this.initialSearchState,
            filterSearch: this.initialSearchState,
        }, () => {
            this.dataTable.current.searchData();
        });
    }
    enableSearch = () => {
        let search = {
            buyeremail: { ...this.state.search.buyeremail, value: this.state.search.buyeremail.value ? this.state.search.buyeremail.value : '' },
        }
     
        this.setState({
            filterSearch: search,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
        
    }
    render() {

        return (
            <div style={{ overflow: "auto", overflowY: "overlay" }}>
                <div className="sale-search">
                    <div className="sales-list-search">
                            <div className="sales-search-container">

                                <Row>
                                    <Col>
                                        <label>Email</label>
                                        <Form.Control onKeyDown={(event) => this._handleKeyDown(event)}  type="text" name="buyeremail" value={this.state.search.buyeremail.value} onChange={(event) => this.searchInputChange(event)} />
                                    </Col>
                                    <Col>
                                        <div className="mt-3">
                                            <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                            <Button variant="search-button"  onClick={() => this.enableSearch()}>Search</Button>
                                        </div>
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                            </div>

                    </div>
                </div>
                
                <Datatable {...this.props} config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} onDataUpdate={this.updateTableData}></Datatable>
            
            </div>
        );
    }
}