import { Component, createRef } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import axios from 'axios';
import { getSellerUrl, getSellerHeaders } from 'shared/http';
import { ArrayValueReorder } from 'shared/modifiers';
import { membershiplist } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';

export class Membership extends Component {

    config;
    initialSearchState;

    constructor(props) {
        super(props);

        this.dataTable = createRef();

        this.initialSearchState = {
            planName: { field: 'planName', value: '', op: 'LIKE', join: 'AND' },
            contact: { field: 'contactname', value: '', op: 'LIKE', join: 'AND' },
            city: { field: 'city', value: '', op: 'LIKE', join: 'AND' },
            state: { field: 'state', value: '', op: 'LIKE', join: 'AND' }
        }

        this.state = {
            search: this.initialSearchState,
            searchData: false,
            issearch: false,
        }

        this.config = {
            name: 'membership',
            url: membershiplist,
            adminUrl: {},
            params: {},
            columns: [
              
                { name: 'Plan Name', property: 'planname', sort: true, width: '250px' },
                { name: 'Start Date', property: 'startdate', sort: true, width: '130px', columnType: 'custom', customColumn: this.customStartDate },
                { name: 'End Date', property: 'enddate', sort: true, width: '130px', columnType: 'custom', customColumn: this.customEndDate },
                { name: 'Total Members', property: 'totalmembers', sort: true, width: '130px' },
                { name: 'Active Members', property: 'activemembers', sort: true, width: '130px' },
            ],
            "field": [
                "id",
                "planname",
                "startdate",
                "enddate",
                "totalmembers",
                "activemembers",
                "plancost",
                "plantax"
            ],
            
            sort: [{ field: 'planname', direction: 'desc' }],
            pagination: true,
            ignoreRowBackground: true
        };


    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.enableSearch()
        }
    }


    componentDidMount() {
        this.enableSearch()
    }
    customStartDate = (value) => {
        if (value['startdate']) {
            return `${moment.utc(value['startdate']).local().format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };
    customEndDate = (value) => {
        if (value['enddate']) {
            return `${moment.utc(value['enddate']).local().format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };
    navigateToMembershipCreate = (value) => {
        this.props.history.push({ pathname: '/membership/create', });

    }


    searchInputChange = (event) => {
        let eventValue = event.target.value;
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [event.target.name]: {
                    ...this.state.search[event.target.name],
                    value: eventValue
                }
            }
        }, () => {

        });
    }
    resetState = () => {
        let issearchData = this.state.searchData;
        this.setState({
            ...this.state,
            search: this.initialSearchState,
            searchData: true,
        }, () => {
            if (issearchData)
                this.dataTable.current.searchData();
        });
    }
    enableSearch = () => {
        let search = {
            planName: { ...this.state.search.planName, value: this.state.search.planName.value ? this.state.search.planName.value + '*' : '' },
        }
        this.setState({
            filterSearch: search,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });

    }
    render() {

        return (
            <div style={{ overflow: "auto", overflowY: "overlay" }}>
                <div className="sale-search mb-3">
                    <div className="sales-list-search">
                        <Form className="sales-list-font">
                            <div className="sales-search-container">

                                <Row>
                                    <Col>
                                        <label>Plan Name</label>
                                        <Form.Control type="text" name="planName" value={this.state.search.planName.value} onChange={(event) => this.searchInputChange(event)} />
                                    </Col>

                                    <Col>
                                        <div className="mt-3">
                                            <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                            <Button variant="search-button" onClick={() => this.enableSearch()}>Search</Button>
                                        </div>
                                    </Col>
                                    <Col>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                            </div>
                        </Form>

                    </div>
                </div>

                <Datatable {...this.props} config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} onDataUpdate={this.updateTableData}></Datatable>

            </div>
        );
    }
}