// library imports
import { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import { SaleItem ,Bidder , Pickup , Sale , Item  } from 'features/components/base/reports';
import SaleItem from "../components/base/reports/SaleItem"
import Bidder from "../components/base/reports/Bidder"
import Pickup from "../components/base/reports/Pickup"
import Sale from "../components/base/reports/Sale"
import Item from "../components/base/reports/Item"
import Admin from "../components/base/reports/Admin"
import Seller from "../components/base/reports/Seller"
import Upcoming from "../components/base/reports/Upcoming"

/**
 * routing for sales components
 * 
 * @param props
 * 
 * @return routing for sales components
 * 
**/

export class ReportRouter extends Component {

    render() {
        return (
            <Switch>
                {/* <Route exact path={`/reports`} component={SaleItem} /> */}
                <Route exact path={`/reports/reportsalesitem`} component={SaleItem} />
                <Route exact path={`/reports/reportbidder`} component={Bidder} />
                <Route exact path={`/reports/reportpickup`} component={Pickup} />
                <Route exact path={`/reports/salereport`} component={Sale} />
                <Route exact path={`/reports/reportitem`} component={Item} />
                <Route exact path={`/reports/reportadmin`} component={Admin} />
                <Route exact path={`/reports/reportseller`} component={Seller} />
                <Route exact path={`/reports/reportupcoming`} component={Upcoming} />


                <Redirect to="/not-found" />
            </Switch>
        );
    }
}

export default connect(state => state)(ReportRouter);