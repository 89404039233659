import React, { useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"
import Datatable from "shared/datatable"
import { declinedCardList } from "shared/settings/apiconfig"
import { responseCodeAndStatus } from "./declindecardstatus"
import * as momentTz from 'moment-timezone';
import moment from "moment"
import "./DeclinedCard.scss"
const DeclinedCards = (props) => {
    const dataTable = useRef()

    const getResponseCode = (values) => {
        const data = JSON.parse(values?.["auditresponse"])
        if(data["data"]) {
            let val = data?.["data"]?.["purchase_units"]?.[0]?.["payments"]?.["authorizations"]?.[0]?.["processor_response"]?.["response_code"]
            return (val ? (`${val}  ${responseCodeAndStatus?.[val] ?` - ${responseCodeAndStatus?.[val]}` : "" }`) : typeof data.message === 'string' ? data.message : '---')
        } else if(data["errorResponse"]) {
            let val = data?.["errorResponse"]?.["message"]
            return (val ? val : "---")
        } else {
            return '---'
        }  
    }
    const getPaypalStatus = (values) => {
        const data = JSON.parse(values?.["auditresponse"])
        if(data["data"]) {
            let val = data?.["data"]?.["purchase_units"]?.[0]?.["payments"]?.["authorizations"]?.[0]?.["status"]
            return (val ? val : '---')
        } else {
            return '---'
        } 
    }
    const getCardNumber = (values) => {
        const data = JSON.parse(values?.["auditresponse"])
        if(data["data"]) {
            let val = data?.["data"]?.["payment_source"]?.["card"]?.["last_digits"]
            return (val ? val : "---")
        } else {
            return '---'
        }  
    }
    const getTime = (values) => {
        return (values? `${moment.utc(values.createddate).local().format('MM/DD/YYYY hh:mm A')+" "+ momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`:"---" )
    }
    const getIssue = (values) => {
        const data = JSON.parse(values?.["auditresponse"])
        if(data["data"]) {
            let val = data?.["data"] ? data?.["data"]?.["details"]?.[0]?.["issue"] : data?.["details"]?.[0]?.["issue"]
            return (val ? val : "---")
        } else if(data["errorResponse"]) {
            let val = data?.["errorResponse"]?.["details"]?.["0"]?.["issue"]
            return (val ? val : "---")
        } else {
            return '---'
        }
    }
    const getPaymentAdviceCode = (values) => {
        const data = JSON.parse(values?.["auditresponse"])
        if(data["data"]) {
            let val = data?.["data"]?.["purchase_units"]?.[0]?.["payments"]?.["authorizations"]?.[0]?.["processor_response"]?.["payment_advice_code"]
            return (val ? val : "---")
        } else {
            return '---'
        }
    }
    const getBrand = (values) => {
        const data = JSON.parse(values?.["auditresponse"])
        if(data["data"]) {
            let val = data?.["data"]?.["payment_source"]?.["card"]?.["brand"]
            return (val ? val : "---")
        } else {
            return '---'
        }
    }
    var config = {
        name: 'declined-cards',
        url: declinedCardList,
        search: {},
        params: {},
        columns: [
            { name: 'Card Number', property: 'last_digits', sort: false, width: '150px', columnType: 'custom', customColumn: getCardNumber },
            { name: 'Brand', property: 'brand', sort: false, width: '170px', columnType: 'custom', customColumn: getBrand },
            { name: 'PAYPAL Status', property: 'status', sort: false, width: '150px', columnType: 'custom', customColumn: getPaypalStatus },
            { name: 'Processor Status', property: 'response_code', sort: false, width: '500px', columnType: 'custom', customColumn: getResponseCode },
            { name: 'Payment Advice Code', property: 'payment_advice_code', sort: false, width: '200px', columnType: 'custom', customColumn: getPaymentAdviceCode },
            { name: 'Issue', property: 'issue', sort: false, width: '150px', columnType: 'custom', customColumn: getIssue },
            { name: 'Created Time', property: 'create_time', sort: false, width: '200px', columnType: 'custom', customColumn: getTime },
        ],
        sort: [
            {
                "field": "update_time",
                "direction": "asc"
            }],
        pagination: true,
        field: [],
        adminUrl: {
            token: null
        }
    };
    const [email, setEmail] = useState("")
    const [postData, setPostData] = useState({
        email: ""
    })
    const [searchData, setSearchData] = useState(false)
    const goSearch = async () => {
        //await not supported for state update but proper search i used await bcoz i faced double GO click for data feching 
        await setSearchData(true)
        await setPostData({
            email,
        })
        await dataTable.current.searchData()
    }
    return (
        <div style={{ overflow: "auto", overflowY: "overlay" }}>
            <div className="d-flex">
                <div className="container">
                    <div className="header">
                        <label>Buyer Email *</label>
                        <div className="d-flex flex-row">
                            <Form.Control style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0
                            }}
                                type="text" name="email" placeholder="Buyer Email"
                                // onKeyDown={goSearch}
                                value={email}
                                onChange={(event) => {

                                    setEmail(event.target.value)
                                }}
                            />
                            <Button variant="search-button"
                                disabled={email.trim() == ""}
                                // disabled={!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))}
                                onClick={() => { goSearch() }}>Go</Button>
                        </div>
                    </div>
                    <div className="border" />
                    <div className="d-flex">
                        <Datatable {...props} config={{ ...config }} data={postData} search={searchData ? { search: {}, searchData: searchData } : null} ref={dataTable} ></Datatable>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeclinedCards